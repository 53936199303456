<template>
  <el-dialog
      :close-on-click-modal="false"
      :visible.sync="visible"
      width="55%"
      title="订单支付"
      :show-close="true"
      @close="payCloseCallback(true)" center>
    <div slot="title" class="tac">
      <h2 v-if="!dataForm.solutionNo">订单支付</h2>
      <h2 v-else>方案支付</h2>
<!--      <el-divider></el-divider>-->
    </div>
    <el-card style="margin-top: -30px" shadow="never">

      <!--        订单支付页面-->
      <div>
        <div class="uf uf-ac">
          <img src="~@/assets/img/icon-payform.png" alt="" style="width: 60px;height: 60px;">
          <div class="ml-5">
            <div class="f22 fwb " v-if="!dataForm.phaseOne">订单提交成功，应付金额：
              <span class="c-red">￥{{dataForm.totalSum}}</span></div>
            <div class="f22 fwb " v-else>订单提交成功，应付金额：<span class="c-red">￥{{price}}</span></div>
            <div class="uf uf-ac mt-3 ">
              <div>
                <span class="f14 c-base">订单编号：</span>
                <span>{{dataForm.orderNo}}</span>
              </div>
              <div>
                <span class="f14 c-base ml-2">服务项目：</span>
                <span>{{dataForm.serviceName}}</span>
              </div>
            </div>
          </div>
        </div>
        <div class="mt-4" v-if="dataForm.phaseOne">
          <div class="uf uf-ac">
            <div style="height:18px; width: 2px; background-color:#409eff;"></div>
            <div class="f18 ml-3" style="width:200px">请支付当前期数</div>
            <div class="ml-3 uf uf-ac" style="margin-left: -33px">
              <el-checkbox-group v-model="payPhase" @change="phaseChange">
                <el-checkbox v-if="dataForm.payPhase===1" :label="1">一期{{dataForm.phaseOne}}</el-checkbox>
                <el-checkbox :label="2" v-if="dataForm.payPhase===2">二期{{dataForm.phaseTwo}}</el-checkbox>
                <el-checkbox :label="3" v-if="dataForm.phaseThree!==0 && dataForm.payPhase===3">
                  三期{{dataForm.phaseThree}}
                </el-checkbox>
                <el-checkbox :label="4" v-if="dataForm.phaseFour!==0 && dataForm.payPhase===4">
                  四期{{dataForm.phaseFour}}
                </el-checkbox>
              </el-checkbox-group>
            </div>
          </div>
        </div>
        <!--         //支付方式-->
        <div class="mt-4">
          <div class="uf uf-ac">
            <div style="height:18px; width: 2px; background-color:#409eff;"></div>
            <div class="f18 ml-3" style="width:200px">请选择支付方式</div>
<!--            <div class="ml-4 mr-5" style="margin-left:-30px">-->
<!--              <el-button @click="online"-->
<!--                         :style="this.payOnLine ? {'color': '#409EFF', 'border-color': '#c6e2ff','background-color': '#ecf5ff'} : {}">-->
<!--                线上支付-->
<!--              </el-button>-->
<!--            </div>-->
            <div>
              <el-button @click="offline"
                         :style="!this.payOnLine ? {'color': '#409EFF', 'border-color': '#c6e2ff','background-color': '#ecf5ff'} : {}">
                线下支付
              </el-button>
            </div>
            <div class="ml-3 uf uf-ac" v-if="!payOnLine">
              <i class="el-icon-warning-outline c-base"></i>
              <div class="f12 ml-2" style="color: rgb(130, 130, 130);">
                账户汇款后，注意保留电子支付票据和支付凭证。
              </div>
            </div>
            <div class="ml-3 uf uf-ac" v-if="payOnLine">
              <i class="el-icon-warning-outline c-base"></i>
              <div class="f12 ml-2" style="color: rgb(130, 130, 130);">
                下单后，可在【个人中心】-【发票管理】申请您所需开具的发票。
              </div>
            </div>
          </div>
        </div>
        <!--         //付款方式-->
<!--        <div class=" mt-4" v-if="payOnLine">-->
<!--          <div v-if="dataForm.payPhase!== null" class="tac f16">-->
<!--            暂不支持分期线上支付-->
<!--          </div>-->
<!--          <div v-else class="uf uf-ac " style="align-items: end">-->
<!--            <div class="uf uf-ac pr-5">-->
<!--              <i class="el-icon-circle-check c-base"></i>-->
<!--              <div class="f18 ml-2">付款方式</div>-->
<!--            </div>-->
<!--            <div>-->
<!--              <el-tabs v-model="payName" type="border-card" style="width: 43rem;margin-bottom: -2px;"-->
<!--                       @tab-click="handleClick">-->
<!--                <el-tab-pane name="wechat_qr">-->
<!--                  <div slot="label">-->
<!--                    <div class="uf uf-ac">-->
<!--                      <i class="iconfont icon-weixinzhifu" style="color: #5daf34"></i>-->
<!--                      <span class=" pl-2 f14">微信支付</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div style="border: 1px solid #ccc;width: 100%; margin:20px auto;" class="mt-3 p-4">-->
<!--                    <div v-if="weTimerSwitch">-->
<!--                      <div class="tac " v-loading="weLoading" :element-loading-text="loadingText">-->
<!--                        <div v-if="qrcodeShow ===0">-->
<!--                          <div id="qr" class="qrcode"  ref="qr">-->
<!--                            <icon-svg class="logoimg" name="weixin"></icon-svg>-->
<!--                          </div>-->
<!--                          <div class="f14 tac mt-4" style="color:rgb(130, 130, 130);">请使用<strong>微信</strong>扫一扫，扫描二维码支付，请在-->
<!--                            {{weTimer}}s内完成支付，过期请刷新支付码-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div  v-if="qrcodeShow ===1" class="tac" style="width: 125px; height: 125px;border: 1px solid #ccc;margin: 0 auto">-->
<!--                          <div style="position: relative;top: 40px">-->
<!--                            <div class="tac pb-1 iconfont icon-duihao "-->
<!--                                 style="color: #67c23a;background: #FFFFFF"></div>-->
<!--                            <div class="tac">支付成功</div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                        <div  v-if="qrcodeShow===2"  class="tac" style="width: 125px; height: 125px;border: 1px solid #ccc;margin: 0 auto">-->
<!--                          <div style="position: relative;top: 40px">-->
<!--                            <div class="tac pb-1 iconfont icon-cuohao "-->
<!--                                 style="color: #67c23a;background: #f56c6c"></div>-->
<!--                            <div class="tac">支付失败</div>-->
<!--                          </div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div v-if="!weTimerSwitch">-->
<!--                      <div class="tac">-->
<!--                        <el-tag type="danger">二维码失效请刷新</el-tag>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="tac mt-4" v-if="!weTimerSwitch">-->
<!--                      <el-button type="primary" @click="weRefresh">点击刷新</el-button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </el-tab-pane>-->
<!--                <el-tab-pane name="alipay_qr">-->
<!--                  <div slot="label">-->
<!--                    <div class="uf uf-ac">-->
<!--                      <i class="iconfont icon-zhifubao" style="color: #409EFF"></i>-->
<!--                      <span class=" pl-2 f14">支付宝支付</span>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                  <div style="border: 1px solid #ccc;width: 100%; margin:20px auto;" class="mt-3 p-4">-->
<!--                    <div v-if="alipayTimerSwitch">-->
<!--                    <div class="tac " v-loading="alipayLoading" :element-loading-text="loadingText">-->
<!--                      <div v-if="qrcodeShow ===0">-->
<!--                        <div id="qrz" class="qrcode"  ref="qrz">-->
<!--                          <icon-svg class="logoimg" name="zhifubao"></icon-svg>-->
<!--                        </div>-->
<!--                        <div class="f14 tac mt-4" style="color:rgb(130, 130, 130);">请使用<strong>支付宝</strong>扫一扫，扫描二维码支付，请在-->
<!--                          {{alipayTimer}}s内完成支付，过期请刷新支付码-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div  v-if="qrcodeShow ===1" class="tac" style="width: 125px; height: 125px;border: 1px solid #ccc;margin: 0 auto">-->
<!--                        <div style="position: relative;top: 40px">-->
<!--                          <div class="tac pb-1 iconfont icon-duihao "-->
<!--                               style="color: #67c23a;background: #FFFFFF"></div>-->
<!--                          <div class="tac">支付成功</div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                      <div  v-if="qrcodeShow===2"  class="tac" style="width: 125px; height: 125px;border: 1px solid #ccc;margin: 0 auto">-->
<!--                        <div style="position: relative;top: 40px">-->
<!--                          <div class="tac pb-1 iconfont icon-cuohao "-->
<!--                               style="color: #67c23a;background: #f56c6c"></div>-->
<!--                          <div class="tac">支付失败</div>-->
<!--                        </div>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    </div>-->
<!--                    <div v-if="!alipayTimerSwitch">-->
<!--                      <div class="tac">-->
<!--                        <el-tag type="danger">二维码失效请刷新</el-tag>-->
<!--                      </div>-->
<!--                    </div>-->
<!--                    <div class="tac mt-4" v-if="!alipayTimerSwitch">-->
<!--                      <el-button type="primary" @click="alipayRefresh">点击刷新</el-button>-->
<!--                    </div>-->
<!--                  </div>-->
<!--                </el-tab-pane>-->
<!--              </el-tabs>-->
<!--            </div>-->
<!--          </div>-->
<!--        </div>-->
        <div class="mt-4">
          <div>
            <div style="border: 1px solid #ccc;width: 600px; margin:20px auto;" class="mt-3 p-4">
              <el-form>
                <el-form-item>
                  <el-row>
                    <el-col :span="5">
                      <span class="label">服务商名称：</span>
                    </el-col>
                    <el-col :span="16">
                      <span>{{supportInfoEntity.enterpriseName}}</span>
                    </el-col>
                  </el-row>
                </el-form-item>
                <div v-if="supportInfoEntity.bankCardNumber">
                  <el-form-item>
                    <el-row>
                      <el-col :span="5">
                        <span class="label">银行账户：</span>
                      </el-col>
                      <el-col :span="16">
                        <span>{{supportInfoEntity.bankCardNumber}}</span>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item>
                    <el-row>
                      <el-col :span="5">
                        <span class="label">账户持有人：</span>
                      </el-col>
                      <el-col :span="16">
                        <span>{{supportInfoEntity.bankCardHolder}}</span>
                      </el-col>
                    </el-row>
                  </el-form-item>
                  <el-form-item>
                    <el-row>
                      <el-col :span="5">
                        <span class="label">账户开户行：</span>
                      </el-col>
                      <el-col :span="16">
                        <span>{{supportInfoEntity.bankCardOpened}}</span>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </div>
                <div v-else>
                  <el-form-item>
                    <el-row>
                      <el-col :span="5">
                        <span class="label">银行账户：</span>
                      </el-col>
                      <el-col :span="16">
                        <span>很抱歉，该信息服务商暂未提供，客服稍后联系您</span>
                      </el-col>
                    </el-row>
                  </el-form-item>
                </div>
              </el-form>
            </div>
          </div>
        </div>
        <el-divider></el-divider>
        <div class="tac">
          <el-button type="success" @click="payConfirm" v-if="!payOnLine && dataForm.payStatus!==5" plain>我已支付
          </el-button>
          <el-button type="success" :disabled="true" v-if="!payOnLine && dataForm.payStatus===5" plain>支付审核中</el-button>
          <el-button type="primary" @click="payCloseCallback(true)" v-if="!payOnLine" plain>我已知晓</el-button>
          <!--                <el-button type="success" @click="paymentSuccessful"  v-if="payOnLine" plain>我已支付</el-button>-->
          <el-button type="danger" @click="payCloseCallback(true)" v-if="payOnLine" plain>取消支付</el-button>
        </div>
      </div>
    </el-card>
  </el-dialog>
</template>

<script>
  import {checkIsNull} from '../../utils'
  import QRCode from 'qrcodejs2'

  export default {
    components: {},
    data() {
      return {
        weLoading: false,
        alipayLoading: false,
        loadingText:'',
        payName: 'alipay_qr',
        wx: true,
        zfb: false,
        payOnLine: true,
        dataForm: {},
        supportInfoEntity: {},
        visible: false,
        confirmAck: true,
        timeout: null,
        phase: false,
        payPhase: [],
        price: 0,

        weTimer: '',
        alipayTimer: '',
        weTimerSwitch: false,
        alipayTimerSwitch: false,
        setWeTimer: null,
        setAlipayTimer: null,
        setListTimer: null,

        wxData:{},
        wxPayVisible:false,
        wxPayType:'',
        qrcodeShow:0,
        qrCode: '',
        qrCodez: ''
      }
    },
    created() {
    },
    methods: {
      // 初始化
      init(info) {
        this.offline()
        this.visible = true
        this.dataForm = info
        this.supportInfoEntity = this.dataForm.supportInfoEntity
        if(!checkIsNull(this.dataForm.payPhase)){
          this.payPhase.push(this.dataForm.payPhase)
          if (this.dataForm.payPhase === 1) {
            this.price = this.dataForm.phaseOne
          }
          if (this.dataForm.payPhase === 2) {
            this.price = this.dataForm.phaseTwo
          }
          if (this.dataForm.payPhase === 3) {
            this.price = this.dataForm.phaseThree
          }
          if (this.dataForm.payPhase === 4) {
            this.price = this.dataForm.phaseFour
          }
        }
        this.getQrCode('alipay_qr')
        this.alipayCountdown(60)
        this.getListTimer()
      },
      // 切换支付方式 change事件
      handleClick(tab, event) {
        if(tab==='wechat_qr'){
          if(!this.weTimerSwitch){
            this.weTimerSwitch = true
            this.getQrCode('wechat_qr')
            this.weCountdown(60)
            this.getListTimer()
          }
        }
        if(tab==='alipay_qr'){
          if(!this.alipayTimerSwitch){
            this.alipayTimerSwitch = true
            this.getQrCode('alipay_qr')
            this.alipayCountdown(60)
            this.getListTimer()
          }
        }
      },
    // 微信支付时间定时器
    weCountdown(time) {
      this.weTimer = time
      if (time < 1) {
        this.weTimerSwitch = false
        clearTimeout(this.setWeTimer)
        clearTimeout(this.setListTimer)
        return
      } else {
        this.weTimerSwitch = true
        this.setWeTimer = setTimeout(() => {
          this.weTimer--
          this.weCountdown(this.weTimer)
        }, 1000)
      }
    },
      // 支付宝支付时间定时器
      alipayCountdown(time) {
        this.alipayTimer = time
        if (time < 1) {
          this.alipayTimerSwitch = false
          clearTimeout(this.setAlipayTimer)
          return
        } else {
          this.alipayTimerSwitch = true
          this.setAlipayTimer = setTimeout(() => {
            this.alipayTimer--
            this.alipayCountdown(this.alipayTimer)
          }, 1000)
        }
      },
    // 获取二维码
    getQrCode(payType) {
      if ('wechat_qr' === payType) {
        this.weLoading = true
      }else{
        this.alipayLoading = true
      }
        this.$http({
          url :this.$http.adornUrl(this.$api.ORDER.PAY_CODE),
          method: 'post',
          data: this.$http.adornData({
            orderNo:this.dataForm.orderNo,
            goodsTitle:this.dataForm.serviceName,
            goodsDesc:this.dataForm.priceDesc,
            amount: this.dataForm.phaseOne === 0 ?this.dataForm.totalSum*100:this.price*100,
            payType: payType,
            payTime: this.dataForm.createTime,
          })
        }).then(({data})=>{
          if ('wechat_qr' === payType) {
            this.weLoading = false
            if (data && data.code ===0) {
                this.qrCode = new QRCode('qr', {
                  width: 150,                     //二维码的宽度
                  height: 150,                    //二维码的高度
                  text: data.data          //调用wxPayment()方法时后台获取的图片url
                  // colorDark: '#04BE02'
                })
              this.weTimerSwitch = true
            }else{
              this.weLoading = false
              this.weTimerSwitch = true
              clearTimeout(this.setWeTimer)
            }
          }
          if ('alipay_qr' === payType) {
            this.alipayLoading = false
            if (data && data.code ===0) {
              this.qrCodez = new QRCode('qrz', {
                width: 150,                     //二维码的宽度
                height: 150,                    //二维码的高度
                text: data.data          //调用wxPayment()方法时后台获取的图片url
                // colorDark: '#207AFF'
              })
              this.alipayTimerSwitch = true
            }else{
              this.alipayLoading = false
              this.alipayTimerSwitch = false
              clearTimeout(this.setAlipayTimer)
            }
          }
        })
    },
      // 查询支付订单状态轮询
      getListTimer() {
        this.setListTimer = setTimeout(() => {
          this.getPayStatus()
          this.getListTimer()
        }, 3000)
      },

      payCloseCallback(refresh) {
        this.payOnLine = true
        this.visible = false
        this.payName = 'alipay_qr'
        this.weTimerSwitch = false
        this.alipayTimerSwitch = false
        clearTimeout(this.setWeTimer)
        clearTimeout(this.setAlipayTimer)
        clearTimeout(this.setListTimer)
        this.$emit('payCloseCallback', refresh)
      },
      offline() {
        if (this.payOnLine) {
          this.payOnLine = false
        }
      },
      online() {
        if (!this.payOnLine) {
          this.payOnLine = true
        }
      },
      //微信刷新
      weRefresh() {
        this.weTimerSwitch = true
        // this.$message.success('刷新成功')
        this.getQrCode('wechat_qr')
        this.weCountdown(60)
        this.getListTimer()
      },
      //支付宝刷新
      alipayRefresh() {
        this.alipayTimerSwitch = true
        // this.$message.success('刷新成功')
        this.getQrCode('alipay_qr')
        this.alipayCountdown(60)
        this.getListTimer()
      },
      //  查询支付订单状态
      getPayStatus () {
        this.$http({
          url: this.$http.adornUrl(this.$api.ORDER.PAY_STATUS),
          methods:'get',
          params: this.$http.adornParams({
            id:this.dataForm.id
          })
        }).then(({data})=>{
          if (data && data.code ===0) {
            if (data.data === 22) {
              this.qrcodeShow = 1
              this.$message.success('支付成功')
              clearTimeout(this.setListTimer)
              setTimeout(()=>{
                this.paymentSuccessful()
              },2000)
            }
            if (data.data === -1) {
              this.$message.success('支付失败，请刷新二维码重新支付')
              this.weTimerSwitch = false
              this.alipayTimerSwitch = false
            }
            if (data.data === -3) {
              this.$message.success('请求超时，请刷新二维码重新支付')
              this.weTimerSwitch = false
              this.alipayTimerSwitch = false
            }
          }
        })
      },
      //跳转支付成功页面
      paymentSuccessful() {
        //延迟跳转
        this.$emit('paymentSuccessful', this.dataForm.orderNo, this.dataForm.totalSum)
      },
      phaseChange(e) {
        this.payPhase.push(this.dataForm.payPhase)
      },
      payConfirm() {
        this.$http({
          url: this.$http.adornUrl(this.$api.ORDER.PAY_CONFIRM),
          method: 'post',
          params: this.$http.adornParams({
            orderId: this.dataForm.id
          })
        }).then(({data}) => {
          if (data.code === 0) {
            this.$message.success('已提醒客服审核，请等待')
            this.payCloseCallback(true)
          } else {
            this.$message.error(data.msg)
          }
        })
      }
  }
      }
</script>

<style scoped>
  .el-checkbox-button {
    margin-left: 5px;
    margin-right: 5px;
    border-radius: 5px;
    border: 1px solid darkgrey;
  }

  .label {
    font-weight: bold;
    font-size: 15px;
    margin-left: 20px;
  }

  .qrcode{
    display:inline-block;
    position:relative;margin-top: .2rem;
  }
  .logoimg{
    position:absolute;
    left:50%;
    top:50%;
    width:60px;
    height:60px;
    margin-top:-35px;
    margin-left:-30px;
    background: white;
  }
</style>
